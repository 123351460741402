<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-20 17:04:01
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-10 14:37:47
-->
<template>
  <div class="check-order-info">
    <a-modal v-model="visible" title="折扣附件" :footer="null" :maskClosable="false">
      <a-spin :spinning="spinning">
        <QiniuUpload
          :disabled="true"
          type="file"
          :value.sync="filesUrl"
          :size="5"
        ></QiniuUpload>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { selectByIdDealerRebate } from '../api/DealerRebateApi'

export default {
  name: '',
  data() {
    return {
      visible: false,
      spinning:false,
      filesUrl:''
    }
  },

  components: {

  },

  computed: {},

  created() {},


  methods: {
    isShow(row) {
      this.spinning = true
      this.visible = true
      selectByIdDealerRebate(row.id).then((res) => {
        this.spinning = false
        this.rowData = res.body
        this.filesUrl = res.body.files
          ? res.body.files
            .map((e) => {
              return e.fileUrl
            })
            .toString()
          : ''
      })

    },


  },
}
</script>

<style lang='scss' scoped>
.order_title,
.row {
  display: flex;
  justify-content: space-between;
}
.order_title {
  margin-bottom: 10px;
}
.row {
  color: rgba(0, 0, 0, 0.8);
}
.order_code {
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
}
.split-line {
  height: 13px;
  background: #ececec;
  margin: 15px 0;
  width: 105%;
  margin-left: -24px;
}
.line {
  width: 105%;
  margin: 15px 0;
  height: 1px;
  background: #e9e9e9;
  margin-left: -24px;
}
.client_info {
  &_row {
    flex-wrap: wrap;
  }
  &_title {
    font-size: 16px;
    color: #333333;
    line-height: 10px;
  }
}
.product_info_title {
  margin-bottom: 12px;
  font-size: 16px;
  color: #333333;
}
.footer-bts {
  text-align: right;
}
.discount {
  text-align: right;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.hyperlinks {
  font-size: 12px;
  color: #888888;
  margin-left: 5px;
}
.hyperlinks:hover {
  color: #4398fa;
}
.fonnter-btns {
  text-align: right;
}
.checkbox_text {
  margin-right: 40px;
}
.line_spacing {
  margin-bottom: 10px;
}
</style>